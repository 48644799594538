"use strict";

exports.__esModule = true;
exports.processCurrency = processCurrency;

var _Numbers = require("../common/Numbers");

function processCurrency() {
  return function (value) {
    // returns NaN if it cannot parse
    var floatValue = Number(value); // stupid JavaScript not isNan check
    // http://adripofjavascript.com/blog/drips/the-problem-with-testing-for-nan-in-javascript.html

    var isValid = !(0, _Numbers.equalsNaN)(floatValue);
    return {
      isValid: isValid,
      hasNewValue: true,
      newValue: floatValue,
      errorDescriptor: "Invalid Currency"
    };
  };
}