"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.InvestmentList = void 0;

require("antd/lib/list/style");

var _list = _interopRequireDefault(require("antd/lib/list"));

var _react = _interopRequireDefault(require("react"));

var _mobxReact = require("mobx-react");

var _InvestmentItem = require("./InvestmentItem");

var InvestmentList = (0, _mobxReact.observer)(function (_ref) {
  var store = _ref.store;
  return _react.default.createElement(_list.default, {
    className: "investments"
  }, store.investments.map(function (investment) {
    return _react.default.createElement(_InvestmentItem.InvestmentItem, {
      key: investment.id,
      investment: investment,
      store: store
    });
  }));
});
exports.InvestmentList = InvestmentList;