"use strict";

exports.__esModule = true;
exports.brokersAustraliaRoute = brokersAustraliaRoute;
exports.BrokersAustraliaPath = exports.BrokersAustraliaSegment = void 0;

var _getPath = require("../../../../../react-static/route/getPath");

var _createRoute = require("../../../../../react-static/route/createRoute");

var _Finance = require("../Finance");

var BrokersAustraliaSegment = "compare-online-brokers-australia";
exports.BrokersAustraliaSegment = BrokersAustraliaSegment;
var BrokersAustraliaPath = (0, _getPath.getPath)(BrokersAustraliaSegment, _Finance.FinancePath);
exports.BrokersAustraliaPath = BrokersAustraliaPath;

function brokersAustraliaRoute(rootPath) {
  return (0, _createRoute.createRoute)(rootPath, BrokersAustraliaPath);
}