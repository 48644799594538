"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.FormattedInput = void 0;

require("antd/lib/input-number/style");

var _inputNumber = _interopRequireDefault(require("antd/lib/input-number"));

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireDefault(require("react"));

var _mobx = require("mobx");

var _mobxReact = require("mobx-react"); // function withFormattedInput2<T, U>(InputNumber: InputNumber) {
// }
// export function withFormattedInput<V, T extends FormattedInputProps<V>, C>(
//   WrappedComponent: React.JSXElementConstructor<T> & C) {
//   return observer(function (props) {
//     const actionFocus = action(function onFocus(event: Event) {
//       props.displayValue.editing = true
//     })
//
//     const actionBlur = action(function onBlur(event: Event) {
//       props.displayValue.editing = false
//     })
//
//     const actionChange = action(function onChange(event: Event) {
//       props.displayValue.inputValue = (event.target as HTMLInputElement).value
//     })
//
//     const { displayValue, ...otherProps } = props
//     return (
//       <WrappedComponent
//         {...otherProps}
//         value={props.displayValue.displayValue}
//         onFocus={actionFocus.bind(this)}
//         onBlur={actionBlur.bind(this)}
//         onChange={actionChange.bind(this)}
//       />
//     )
//   })
// }


var FormattedInput = (0, _mobxReact.observer)(function (props) {
  var actionFocus = (0, _mobx.action)(function () {
    props.displayValue.editing = true;
  });
  var actionBlur = (0, _mobx.action)(function () {
    props.displayValue.editing = false;
  });
  var actionChange = (0, _mobx.action)(function (value) {
    props.displayValue.inputValue = value;
  });
  var displayValue = props.displayValue,
      otherProps = (0, _objectWithoutProperties2.default)(props, ["displayValue"]);
  return _react.default.createElement(_inputNumber.default, (0, _extends2.default)({}, otherProps, {
    value: props.displayValue.displayValue,
    onFocus: actionFocus,
    onBlur: actionBlur,
    onChange: actionChange
  }));
});
exports.FormattedInput = FormattedInput;