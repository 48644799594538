"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.productFee = productFee;

var _big = _interopRequireDefault(require("big.js"));

function productFee(total, feePercentage, feePa) {
  var totalN = (0, _big.default)(total);
  var feePercentageN = (0, _big.default)(feePercentage);
  var feePaN = (0, _big.default)(feePa);
  return feePaN.plus(totalN.times(feePercentageN.div(100)));
}