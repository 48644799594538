"use strict";

exports.__esModule = true;
exports.bmiCalculatorRoute = bmiCalculatorRoute;
exports.BmiCalculatorPath = exports.BmiCalculatorSegment = void 0;

var _Health = require("../Health");

var _getPath = require("../../../../../react-static/route/getPath");

var _createRoute = require("../../../../../react-static/route/createRoute");

var BmiCalculatorSegment = "bmi-calculator";
exports.BmiCalculatorSegment = BmiCalculatorSegment;
var BmiCalculatorPath = (0, _getPath.getPath)(BmiCalculatorSegment, _Health.HealthPath);
exports.BmiCalculatorPath = BmiCalculatorPath;

function bmiCalculatorRoute(rootPath) {
  return (0, _createRoute.createRoute)(rootPath, BmiCalculatorPath);
}