"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.FinanceDetails = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

require("antd/lib/form/style");

var _form = _interopRequireDefault(require("antd/lib/form"));

var _react = _interopRequireDefault(require("react"));

var _mobxReact = require("mobx-react");

var _ForecastInputs = _interopRequireDefault(require("./ForecastInputs"));

var _FormattedInput = require("../../../../components/FormattedInput");

var _FinanceDetailsForm = require("./FinanceDetailsForm");

var _StartingBalanceFormInput = require("./StartingBalanceFormInput");

var FormItem = _form.default.Item;
var FinanceDetails = (0, _mobxReact.observer)(function (_ref) {
  var store = _ref.store;
  var monthlyIncomeId = "monthlyIncome";
  return _react.default.createElement(_FinanceDetailsForm.FinanceDetailsForm, null, _react.default.createElement(_StartingBalanceFormInput.StartingBalanceFormInput, {
    totalInvestment: store.totalInvestment
  }), _react.default.createElement(FormItem, (0, _extends2.default)({
    label: "Monthly Income (+ or -)"
  }, !store.monthlyIncome.isValid && {
    help: store.monthlyIncome.error.errorDescriptor,
    validateStatus: "error"
  }), _react.default.createElement(_FormattedInput.FormattedInput, {
    id: monthlyIncomeId,
    displayValue: store.monthlyIncome,
    type: "text"
  })), _react.default.createElement(_ForecastInputs.default, {
    store: store
  }));
});
exports.FinanceDetails = FinanceDetails;