"use strict";

exports.__esModule = true;
exports.etoroReviewRoute = etoroReviewRoute;
exports.EtoroReviewPath = exports.EtoroReviewSegment = void 0;

var _getPath = require("../../../../../react-static/route/getPath");

var _createRoute = require("../../../../../react-static/route/createRoute");

var _Finance = require("../Finance");

var EtoroReviewSegment = "etoro-review";
exports.EtoroReviewSegment = EtoroReviewSegment;
var EtoroReviewPath = (0, _getPath.getPath)(EtoroReviewSegment, _Finance.FinancePath);
exports.EtoroReviewPath = EtoroReviewPath;

function etoroReviewRoute(rootPath) {
  return (0, _createRoute.createRoute)(rootPath, EtoroReviewPath);
}