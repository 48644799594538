"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.partialFee = partialFee;

var _big = _interopRequireDefault(require("big.js"));

function partialFee(total, feePercent, portfolioPercent) {
  var totalN = (0, _big.default)(total);
  var feePercentN = (0, _big.default)(feePercent);
  var portfolioPercentN = (0, _big.default)(portfolioPercent);
  return totalN.times(feePercentN.div(100)).times(portfolioPercentN.div(100));
}