"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.Investments = void 0;

require("antd/lib/alert/style");

var _alert = _interopRequireDefault(require("antd/lib/alert"));

var _react = _interopRequireDefault(require("react"));

var _Disclaimer = _interopRequireDefault(require("./Disclaimer"));

var _InvestmentsContainer = require("./InvestmentsContainer");

var _InvestmentList = require("./InvestmentList");

var _mobxReact = require("mobx-react");

var Investments = (0, _mobxReact.observer)(function (_ref) {
  var compoundInterestStore = _ref.compoundInterestStore;
  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_InvestmentsContainer.InvestmentsContainer, {
    store: compoundInterestStore
  }, _react.default.createElement("div", null, compoundInterestStore.investments.length > 0 ? _react.default.createElement(_InvestmentList.InvestmentList, {
    store: compoundInterestStore
  }) : _react.default.createElement(_alert.default, {
    type: "warning",
    message: "No Investments, please add."
  }))), _react.default.createElement(_Disclaimer.default, null));
});
exports.Investments = Investments;