"use strict";

exports.__esModule = true;
exports.FinanceCalculatorsPath = exports.FinanceCalculatorsSegment = exports.FinanceId = exports.FinanceTitle = exports.FinancePath = exports.FinanceSegment = void 0;

var _getPath = require("../../../../react-static/route/getPath");

var _createId = require("../../../../html/createId");

var FinanceSegment = "finance";
exports.FinanceSegment = FinanceSegment;
var FinancePath = (0, _getPath.getPath)(FinanceSegment);
exports.FinancePath = FinancePath;
var FinanceTitle = "Finance";
exports.FinanceTitle = FinanceTitle;
var FinanceId = (0, _createId.createId)(FinanceTitle);
exports.FinanceId = FinanceId;
var FinanceCalculatorsSegment = "calculators";
exports.FinanceCalculatorsSegment = FinanceCalculatorsSegment;
var FinanceCalculatorsPath = (0, _getPath.getPath)(FinanceCalculatorsSegment, FinancePath);
exports.FinanceCalculatorsPath = FinanceCalculatorsPath;