"use strict";

exports.__esModule = true;
exports.isRequired = isRequired;

function isRequired() {
  return function (value) {
    if (value === undefined || value === null || typeof value === "string" && value.trim() === "") {
      return {
        isValid: false,
        errorDescriptor: "Required"
      };
    } else {
      return {
        isValid: true
      };
    }
  };
}