"use strict";

exports.__esModule = true;
exports.runIfArgsValid = runIfArgsValid;

var _IsValid = require("./IsValid");

function runIfArgsValid(func) {
  var result = {};

  for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    args[_key - 1] = arguments[_key];
  }

  result.isValid = _IsValid.isValidArgs.apply(void 0, args);

  if (result.isValid) {
    var newArgs = args.map(function (arg) {
      return arg.value;
    });
    result.returned = func.apply(void 0, newArgs);
  }

  return result;
}