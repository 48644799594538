"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.LossValue = void 0;

var _taggedTemplateLiteralLoose2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteralLoose"));

var _styled = require("../../styles/styled");

function _templateObject() {
  var data = (0, _taggedTemplateLiteralLoose2.default)(["\n  color: #ff0000;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var LossValue = _styled.styled.div(_templateObject());

exports.LossValue = LossValue;