"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.ProductItem = void 0;

require("antd/lib/button/style");

var _button = _interopRequireDefault(require("antd/lib/button"));

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

require("antd/lib/input/style");

var _input = _interopRequireDefault(require("antd/lib/input"));

require("antd/lib/form/style");

var _form = _interopRequireDefault(require("antd/lib/form"));

require("antd/lib/list/style");

var _list = _interopRequireDefault(require("antd/lib/list"));

var _react = _interopRequireDefault(require("react"));

var _mobx = require("mobx");

var _mobxReact = require("mobx-react");

var _ProductFee = require("./ProductFee");

var _FormattedNumberInput = require("../../../../components/FormattedNumberInput");

var ListItem = _list.default.Item;
var FormItem = _form.default.Item;
var feePercentageLabel = "Fee %";
var portfolioPercentageLabel = "Portfolio %";
var estimatedReturnPercentageLabel = "Estimated Return %";
var ProductItem = (0, _mobxReact.observer)(function (_ref) {
  var investment = _ref.investment,
      product = _ref.product,
      store = _ref.store;
  var actionNameChange = (0, _mobx.action)(function onNameChange(event) {
    product.name.inputValue = event.target.value;
  });
  var actionFeePercentageChange = (0, _mobx.action)(function onFeePercentageChange(event) {
    product.feePercentage.inputValue = +event.target.value;
  });
  var actionPortfolioPercentageChange = (0, _mobx.action)(function onPortfolioPercentageChange(event) {
    product.portfolioPercentage.inputValue = +event.target.value;
  });
  var actionEstimatedReturnPercentageChange = (0, _mobx.action)(function onEstimatedReturnPercentageChange(event) {
    product.estimatedReturnPercentage.inputValue = +event.target.value;
  });

  var onDelete = function onDelete(investmentId, productId) {
    store.deleteProduct(investmentId, productId);
  };

  var postfix = "_" + investment.id + "_" + product.id;
  var feePercentageId = "feePercentage_" + postfix;
  var portfolioPercentageId = "portfolioPercentage_" + postfix;
  var estimatedReturnPercentageId = "estimatedReturnPercentage_" + postfix;
  return _react.default.createElement(ListItem, null, _react.default.createElement(FormItem, {
    label: "Product Name"
  }, _react.default.createElement(_input.default, {
    type: "text",
    className: "productName",
    value: product.name.inputValue,
    onChange: actionNameChange
  })), _react.default.createElement(FormItem, (0, _extends2.default)({
    label: feePercentageLabel,
    htmlFor: feePercentageId
  }, !product.feePercentage.isValid && {
    help: product.feePercentage.error.errorDescriptor,
    validateStatus: "error"
  }), _react.default.createElement(_FormattedNumberInput.FormattedNumberInput, {
    id: feePercentageId,
    displayValue: product.feePercentage,
    onChange: actionFeePercentageChange
  })), _react.default.createElement(FormItem, (0, _extends2.default)({
    label: portfolioPercentageLabel,
    htmlFor: estimatedReturnPercentageId
  }, !product.portfolioPercentage.isValid && {
    help: product.portfolioPercentage.error.errorDescriptor,
    validateStatus: "error"
  }), _react.default.createElement(_FormattedNumberInput.FormattedNumberInput, {
    id: estimatedReturnPercentageId,
    displayValue: product.portfolioPercentage,
    onChange: actionPortfolioPercentageChange
  })), _react.default.createElement(FormItem, (0, _extends2.default)({
    label: estimatedReturnPercentageLabel,
    htmlFor: estimatedReturnPercentageId
  }, !product.estimatedReturnPercentage.isValid && {
    help: product.estimatedReturnPercentage.error.errorDescriptor,
    validateStatus: "error"
  }), _react.default.createElement(_FormattedNumberInput.FormattedNumberInput, {
    id: estimatedReturnPercentageId,
    displayValue: product.estimatedReturnPercentage,
    onChange: actionEstimatedReturnPercentageChange
  })), _react.default.createElement(_ProductFee.ProductFee, {
    product: product,
    store: store
  }), _react.default.createElement(_button.default, {
    onClick: function onClick() {
      return onDelete(investment.id, product.id);
    }
  }, "Delete"));
});
exports.ProductItem = ProductItem;