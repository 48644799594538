"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.FormattedNumberInput = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _react = _interopRequireDefault(require("react"));

var _FormattedInput = require("./FormattedInput");

var FormattedNumberInput = function FormattedNumberInput(props) {
  return _react.default.createElement(_FormattedInput.FormattedInput, (0, _extends2.default)({
    inputMode: "decimal",
    pattern: "[-+]?[0-9]*[.,]?[0-9]+"
  }, props));
};

exports.FormattedNumberInput = FormattedNumberInput;