"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.StartingBalanceFormInput = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

require("antd/lib/form/style");

var _form = _interopRequireDefault(require("antd/lib/form"));

var _react = _interopRequireDefault(require("react"));

var _mobxReact = require("mobx-react");

var _FormattedNumberInput = require("../../../../components/FormattedNumberInput");

var FormItem = _form.default.Item;
var StartingBalanceFormInput = (0, _mobxReact.observer)(function (_ref) {
  var totalInvestment = _ref.totalInvestment;
  var totalInvestmentId = "totalInvestment";
  return _react.default.createElement(FormItem, (0, _extends2.default)({
    label: "Starting Balance",
    htmlFor: totalInvestmentId
  }, !totalInvestment.isValid && {
    help: totalInvestment.error.errorDescriptor,
    validateStatus: "error"
  }), _react.default.createElement(_FormattedNumberInput.FormattedNumberInput, {
    id: totalInvestmentId,
    style: {
      width: "200px"
    },
    displayValue: totalInvestment
  }));
});
exports.StartingBalanceFormInput = StartingBalanceFormInput;