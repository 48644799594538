"use strict";

exports.__esModule = true;
exports.HealthId = exports.HealthTitle = exports.HealthPath = exports.HealthSegment = void 0;

var _getPath = require("../../../../react-static/route/getPath");

var _createId = require("../../../../html/createId");

var HealthSegment = "health";
exports.HealthSegment = HealthSegment;
var HealthPath = (0, _getPath.getPath)(HealthSegment);
exports.HealthPath = HealthPath;
var HealthTitle = "Health";
exports.HealthTitle = HealthTitle;
var HealthId = (0, _createId.createId)(HealthTitle);
exports.HealthId = HealthId;