"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.forecastTotals = forecastTotals;

var _big = _interopRequireDefault(require("big.js"));

var _Numbers = require("../../../../common/Numbers");

function forecastTotals(totalInvestment, monthlyIncome, yearsToForecast, adminFeePa, adminFee, products, currenyDecimalPlaces) {
  //console.log('Financials: ', financials)
  //console.log('investment: ', investment)
  //console.log('products: ', products)
  //financials.yearsToForecast
  //financials.totalInvestment
  //investment.adminFeePa
  //investment.adminFee
  //products[p].portfolioPercentage
  //products[p].estimatedReturnPercentage
  //products[p].feePercentage
  //financials.monthlyIncome
  //return
  //todo.futureValue
  //todo.totalFees
  if ((0, _Numbers.equalsNaN)(totalInvestment) || !((0, _Numbers.equalsNaN)(monthlyIncome) || !(0, _Numbers.equalsNaN)(yearsToForecast))) return;
  var years = yearsToForecast;
  var months = years * 12;
  var futureValue = (0, _big.default)(totalInvestment);
  var localTotalFees = (0, _big.default)(0);

  for (var month = 1; month <= months; month++) {
    //check for a new year to charge a fee
    //console.log('i % 12: ', i % 12)
    if (month % 12 === 1) {
      // console.log('investment.adminFeePa: ', investment.adminFeePa);
      // console.log('before adminPa localTotalFees: ', localTotalFees);
      // console.log('before adminPa: ', investment);
      var annualFundPercentageFee = (0, _big.default)(adminFee).div(100);
      var annualPercentagefee = futureValue.times(annualFundPercentageFee).round(currenyDecimalPlaces); // console.log('annualPercentagefee: ', annualPercentagefee);

      futureValue = futureValue.minus(annualPercentagefee);
      localTotalFees = localTotalFees.plus(annualPercentagefee);
      futureValue = futureValue.minus(adminFeePa);
      localTotalFees = localTotalFees.plus(adminFeePa); // console.log('after adminPa: ', localTotalFees);
    }

    var investmentThisMonth = (0, _big.default)(0); //          if (i === 1) {
    //            investmentThisMonth = financials.totalInvestment;
    //          }
    //          else {

    investmentThisMonth = futureValue; //          }
    //console.log('investmentThisMonth: ', investmentThisMonth)

    for (var p = 0; p < products.length; p++) {
      var investmentInProductThisMonth = investmentThisMonth.times((0, _big.default)(products[p].portfolioPercentage.value).div(100));
      var annualReturnRate = (0, _big.default)(products[p].estimatedReturnPercentage.value);
      var monthlyReturnRate = annualReturnRate.div(12).div(100); //console.log('monthlyRate: ', monthlyRate)

      var profitMade = investmentInProductThisMonth.times(monthlyReturnRate).round(currenyDecimalPlaces);
      var annualFeeRate = (0, _big.default)(products[p].feePercentage.value);
      var monthlyFeeRate = annualFeeRate.div(12).div(100); //console.log('monthlyFeeRate: ', monthlyFeeRate)

      var monthlyPercentagefee = investmentInProductThisMonth.times(monthlyFeeRate).round(currenyDecimalPlaces); //console.log('profitMade: ', profitMade)

      futureValue = futureValue.plus(profitMade).minus(monthlyPercentagefee);
      localTotalFees = localTotalFees.plus(monthlyPercentagefee); //console.log('futureValue: ', futureValue)
    } // console.log('financials.monthlyIncome: ', financials.monthlyIncome)


    futureValue = futureValue.plus(monthlyIncome); //console.log('todo: ', todo)
  }

  return {
    //console.log('futureValue: ', futureValue)
    futureValue: futureValue.round(currenyDecimalPlaces),
    //console.log('localTotalFees: ', localTotalFees)
    totalFees: localTotalFees.round(currenyDecimalPlaces)
  }; //return precise_round(futureValue, 2);
  //return financials.value.existingSuperBalance;
  //return precise_round(existingSuperBalance * adminFee / 100, 2);
}