"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.TimingBreadcrumb = TimingBreadcrumb;

var _react = _interopRequireDefault(require("react"));

var _Breadcrumbs = require("../../../../components/Breadcrumb/Breadcrumbs");

var _Timing = require("./Timing");

var _Home = require("../Home");

function TimingBreadcrumb() {
  return _react.default.createElement(_Breadcrumbs.Breadcrumbs, {
    items: [{
      label: _Timing.TimingTitle,
      to: _Home.TimingTileRef
    }]
  });
}