"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.default = void 0;

var _taggedTemplateLiteralLoose2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteralLoose"));

var _react = _interopRequireDefault(require("react"));

var _mobxReact = require("mobx-react");

var _mobx = require("mobx");

var _forecastTotals = require("../calculations/forecastTotals");

var _DisplayValue = require("../../../../common/DisplayValue");

var _Core = require("../../../../components/Core");

var _styled = require("../../../../styles/styled");

var _LossValue = require("../../../../components/dataDisplay/LossValue");

function _templateObject2() {
  var data = (0, _taggedTemplateLiteralLoose2.default)(["\n  font-size: 1.4rem;\n  padding-right: 30px;\n  // color: nth($brand-accent-colors, 1);\n  color: white;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteralLoose2.default)(["\n  background-color: #2c2f3c;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var InvestmentSummaryWrapper = (0, _styled.styled)(_Core.Flex)(_templateObject());

var SummaryItem = _styled.styled.label(_templateObject2());

function InvestmentSummary(_ref) {
  var investment = _ref.investment,
      store = _ref.store;
  var forecastedTotals = (0, _mobx.computed)(function () {
    return (0, _forecastTotals.forecastTotals)(store.totalInvestment.value, store.monthlyIncome.value, store.yearsToForecast, investment.adminFeePa.value, investment.adminFeePercentage.value, investment.products, 2);
  });
  var forecastTotal = new _DisplayValue.DisplayValue(forecastedTotals.get().futureValue, store.currencyFormatter);
  var totalFees = new _DisplayValue.DisplayValue(forecastedTotals.get().totalFees, store.currencyFormatter);
  return _react.default.createElement(InvestmentSummaryWrapper, {
    flexDirection: "row",
    px: 2
  }, _react.default.createElement(SummaryItem, {
    className: "forecastTotal"
  }, "Forecast Total: ", forecastTotal.displayValue), _react.default.createElement(SummaryItem, null, _react.default.createElement(_LossValue.LossValue, null, "Total Fees: ", totalFees.displayValue)));
}

var _default = (0, _mobxReact.observer)(InvestmentSummary);

exports.default = _default;