"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.InvestmentAdminCost = void 0;

require("antd/lib/form/style");

var _form = _interopRequireDefault(require("antd/lib/form"));

var _react = _interopRequireDefault(require("react"));

var _mobxReact = require("mobx-react");

var _mobx = require("mobx");

var _productFee = require("../calculations/productFee");

var _RunIfArgsValid = require("../../../../common/RunIfArgsValid");

var _DisplayValue = require("../../../../common/DisplayValue");

var _LossValue = require("../../../../components/dataDisplay/LossValue");

var FormItem = _form.default.Item;
var adminCostLabel = "Admin Cost";
var InvestmentAdminCost = (0, _mobxReact.observer)(function (_ref) {
  var investment = _ref.investment,
      store = _ref.store;
  var adminCost = (0, _mobx.computed)(function () {
    return (0, _RunIfArgsValid.runIfArgsValid)(_productFee.productFee, store.totalInvestment, investment.adminFeePercentage, investment.adminFeePa);
  });
  var adminCostFormatted = new _DisplayValue.DisplayValue(adminCost.get().returned, store.currencyFormatter);
  return _react.default.createElement(FormItem, {
    label: adminCostLabel
  }, _react.default.createElement(_LossValue.LossValue, null, adminCostFormatted.displayValue));
});
exports.InvestmentAdminCost = InvestmentAdminCost;