"use strict";

exports.__esModule = true;
exports.buyingSilverInAustraliaRoute = buyingSilverInAustraliaRoute;
exports.BuyingSilverInAustraliaPath = exports.BuyingSilverInAustraliaSegment = void 0;

var _getPath = require("../../../../../react-static/route/getPath");

var _createRoute = require("../../../../../react-static/route/createRoute");

var _Finance = require("../Finance");

var BuyingSilverInAustraliaSegment = "buying-silver-in-australia";
exports.BuyingSilverInAustraliaSegment = BuyingSilverInAustraliaSegment;
var BuyingSilverInAustraliaPath = (0, _getPath.getPath)(BuyingSilverInAustraliaSegment, _Finance.FinancePath);
exports.BuyingSilverInAustraliaPath = BuyingSilverInAustraliaPath;

function buyingSilverInAustraliaRoute(rootPath) {
  return (0, _createRoute.createRoute)(rootPath, BuyingSilverInAustraliaPath);
}