"use strict";

exports.__esModule = true;
exports.TimingId = exports.TimingTitle = exports.TimingPath = exports.TimingSegment = void 0;

var _getPath = require("../../../../react-static/route/getPath");

var _createId = require("../../../../html/createId");

var TimingSegment = "timing";
exports.TimingSegment = TimingSegment;
var TimingPath = (0, _getPath.getPath)(TimingSegment);
exports.TimingPath = TimingPath;
var TimingTitle = "Timing";
exports.TimingTitle = TimingTitle;
var TimingId = (0, _createId.createId)(TimingTitle);
exports.TimingId = TimingId;