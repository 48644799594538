"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.default = void 0;

require("antd/lib/card/style");

var _card = _interopRequireDefault(require("antd/lib/card"));

require("antd/lib/input-number/style");

var _inputNumber = _interopRequireDefault(require("antd/lib/input-number"));

require("antd/lib/form/style");

var _form = _interopRequireDefault(require("antd/lib/form"));

var _react = _interopRequireDefault(require("react"));

var _mobx = require("mobx");

var _mobxReact = require("mobx-react");

var FormItem = _form.default.Item;

function ForecastInputs(_ref) {
  var store = _ref.store; // const onForecastTypeChange = (event: Event) => {
  //   uiStore.forecastType = (event.target as HTMLInputElement).value;
  // };

  var actionYearsToForecastChange = (0, _mobx.action)(function onYearsToForecastChange(value) {
    // const value = (event.target as HTMLInputElement).value
    var newNumber = Number(value);

    if (newNumber === newNumber) {
      store.yearsToForecast = newNumber;
    }
  });
  var yearToForecastId = "yearsToForecast"; // const forecastName = 'forecast';

  return _react.default.createElement(_card.default, {
    title: "Forecast Details",
    size: "small",
    bordered: false
  }, _react.default.createElement(FormItem, {
    label: "Years to Forecast",
    htmlFor: yearToForecastId
  }, _react.default.createElement(_inputNumber.default, {
    id: yearToForecastId,
    min: 0,
    value: store.yearsToForecast,
    onChange: actionYearsToForecastChange
  })));
} // FormField({ label: 'Return', name: 'forecast',
//     value: uiStore.forecastType }, [
//   RadioButton({ label: 'Estimated Return %', value: 'estimated', name: forecastName,
//       checked: uiStore.forecastType === 'estimated',
//       onChange: onForecastTypeChange }),
//   legend('Using Past Years Performance'),
//   RadioButton({ label: '1', value: '1', name: forecastName,
//       checked: uiStore.forecastType === '1',
//       onChange: onForecastTypeChange }),
//   RadioButton({ label: '3', value: '3', name: forecastName,
//       checked: uiStore.forecastType === '3',
//       onChange: onForecastTypeChange }),
//   RadioButton({ label: '5', value: '5', name: forecastName,
//       checked: uiStore.forecastType === '5',
//       onChange: onForecastTypeChange }),
//   RadioButton({ label: '10', value: '10', name: forecastName,
//       checked: uiStore.forecastType === '10',
//       onChange: onForecastTypeChange }),
// ])


var _default = (0, _mobxReact.observer)(ForecastInputs);

exports.default = _default;