"use strict";

exports.__esModule = true;
exports.compoundInterestRoute = compoundInterestRoute;
exports.CompoundInterestPath = exports.CompoundInterestSegment = void 0;

var _getPath = require("../../../../../react-static/route/getPath");

var _createRoute = require("../../../../../react-static/route/createRoute");

var _Calculators = require("../Calculators");

var CompoundInterestSegment = "compound-interest";
exports.CompoundInterestSegment = CompoundInterestSegment;
var CompoundInterestPath = (0, _getPath.getPath)(CompoundInterestSegment, _Calculators.CalculatorsPath);
exports.CompoundInterestPath = CompoundInterestPath;

function compoundInterestRoute(rootPath) {
  return (0, _createRoute.createRoute)(rootPath, CompoundInterestPath);
}