"use strict";

exports.__esModule = true;
exports.financialAdviserCalculatorRoute = financialAdviserCalculatorRoute;
exports.FinancialAdviserCalculatorPath = exports.FinancialAdviserCalculatorSegment = void 0;

var _getPath = require("../../../../../../react-static/route/getPath");

var _createRoute = require("../../../../../../react-static/route/createRoute");

var _Finance = require("../../Finance");

var FinancialAdviserCalculatorSegment = "financial-adviser-fees-compared";
exports.FinancialAdviserCalculatorSegment = FinancialAdviserCalculatorSegment;
var FinancialAdviserCalculatorPath = (0, _getPath.getPath)(FinancialAdviserCalculatorSegment, _Finance.FinanceCalculatorsPath);
exports.FinancialAdviserCalculatorPath = FinancialAdviserCalculatorPath;

function financialAdviserCalculatorRoute(rootPath) {
  return (0, _createRoute.createRoute)(rootPath, FinancialAdviserCalculatorPath);
}