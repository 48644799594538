"use strict";

exports.__esModule = true;
exports.defaultProduct = defaultProduct;

var _ReactiveData = require("../../../../common/ReactiveData");

var _PercentageValue = require("../../../../common/PercentageValue");

function defaultProduct(id, isFirst) {
  return {
    id: id,
    name: new _ReactiveData.ReactiveData(""),
    feePercentage: new _PercentageValue.PercentageValue("0"),
    portfolioPercentage: new _PercentageValue.PercentageValue(isFirst ? "100" : "0"),
    estimatedReturnPercentage: new _PercentageValue.PercentageValue("0") // isValid: feePercentage.IsValid

  };
}