"use strict";

exports.__esModule = true;
exports.stopwatchRoute = stopwatchRoute;
exports.StopwatchPath = exports.StopwatchSegment = void 0;

var _Timing = require("../Timing");

var _getPath = require("../../../../../react-static/route/getPath");

var _createRoute = require("../../../../../react-static/route/createRoute");

var StopwatchSegment = "stopwatch";
exports.StopwatchSegment = StopwatchSegment;
var StopwatchPath = (0, _getPath.getPath)(StopwatchSegment, _Timing.TimingPath);
exports.StopwatchPath = StopwatchPath;

function stopwatchRoute(rootPath) {
  return (0, _createRoute.createRoute)(rootPath, StopwatchPath);
}