"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.InvestmentItem = void 0;

require("antd/lib/button/style");

var _button = _interopRequireDefault(require("antd/lib/button"));

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

require("antd/lib/input/style");

var _input = _interopRequireDefault(require("antd/lib/input"));

require("antd/lib/form/style");

var _form = _interopRequireDefault(require("antd/lib/form"));

require("antd/lib/list/style");

var _list = _interopRequireDefault(require("antd/lib/list"));

var _react = _interopRequireDefault(require("react"));

var _mobx = require("mobx");

var _mobxReact = require("mobx-react");

var _InvestmentAdminCost = require("./InvestmentAdminCost");

var _InvestmentSummary = _interopRequireDefault(require("./InvestmentSummary"));

var _Products = require("./Products");

var _FormattedNumberInput = require("../../../../components/FormattedNumberInput");

var ListItem = _list.default.Item;
var FormItem = _form.default.Item;
var adminFeeLabel = "Admin Fee %";
var adminFeePaLabel = "Admin Fee (pa)";
var InvestmentItem = (0, _mobxReact.observer)(function (_ref) {
  var investment = _ref.investment,
      store = _ref.store;
  var actionNameChange = (0, _mobx.action)(function onNameChange(event) {
    investment.name.inputValue = event.target.value;
  });
  var actionAdminFeePercentageChange = (0, _mobx.action)(function onAdminFeePercentageChange(event) {
    // TODO: do I need the +?
    investment.adminFeePercentage.inputValue = +event.target.value;
  });
  var actionAdminFeePaChange = (0, _mobx.action)(function onAdminFeePaChange(event) {
    // TODO: do I need the +?
    investment.adminFeePa.inputValue = +event.target.value;
  });

  var onDelete = function onDelete(id) {
    store.deleteInvestment(id);
  };

  var investmentNameId = "investmentName";
  var adminFeeId = "adminFee";
  var adminFeePaId = "adminFeePa";
  var formItemLayout = {
    labelCol: {
      xs: {
        span: 34
      },
      sm: {
        span: 5
      }
    },
    wrapperCol: {
      xs: {
        span: 34
      },
      sm: {
        span: 12
      }
    }
  };
  return _react.default.createElement(ListItem, null, _react.default.createElement(_list.default, {
    bordered: false,
    split: false
  }, _react.default.createElement(ListItem, null, _react.default.createElement(FormItem, {
    label: "Investment Name"
  }, _react.default.createElement(_input.default, {
    id: investmentNameId,
    type: "text",
    value: investment.name.inputValue,
    onChange: actionNameChange
  })), _react.default.createElement(FormItem, (0, _extends2.default)({
    label: adminFeeLabel,
    htmlFor: adminFeeId
  }, !investment.adminFeePercentage.isValid && {
    help: investment.adminFeePercentage.error.errorDescriptor,
    validateStatus: "error"
  }), _react.default.createElement(_FormattedNumberInput.FormattedNumberInput, {
    id: adminFeeId,
    displayValue: investment.adminFeePercentage,
    onChange: actionAdminFeePercentageChange
  })), _react.default.createElement(FormItem, (0, _extends2.default)({
    label: adminFeePaLabel,
    htmlFor: adminFeePaId
  }, !investment.adminFeePa.isValid && {
    help: investment.adminFeePa.error.errorDescriptor,
    validateStatus: "error"
  }), _react.default.createElement(_FormattedNumberInput.FormattedNumberInput, {
    id: adminFeePaId,
    displayValue: investment.adminFeePa,
    onChange: actionAdminFeePaChange
  })), _react.default.createElement(_InvestmentAdminCost.InvestmentAdminCost, {
    investment: investment,
    store: store
  }), _react.default.createElement(FormItem, null, _react.default.createElement(_button.default, {
    onClick: function onClick() {
      return onDelete(investment.id);
    }
  }, "Delete"))), store.displayProducts && _react.default.createElement(ListItem, null, _react.default.createElement(_Products.Products, {
    store: store,
    investment: investment
  })), _react.default.createElement(ListItem, null, _react.default.createElement(_InvestmentSummary.default, {
    store: store,
    investment: investment
  }))));
});
exports.InvestmentItem = InvestmentItem;