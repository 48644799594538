"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.FinanceDetailsForm = void 0;

require("antd/lib/form/style");

var _form = _interopRequireDefault(require("antd/lib/form"));

require("antd/lib/collapse/style");

var _collapse = _interopRequireDefault(require("antd/lib/collapse"));

var _react = _interopRequireDefault(require("react"));

var Panel = _collapse.default.Panel;

var FinanceDetailsForm = function FinanceDetailsForm(_ref) {
  var children = _ref.children;
  return _react.default.createElement(_collapse.default, {
    className: "financeDetails",
    defaultActiveKey: ["financeDetails"]
  }, _react.default.createElement(Panel, {
    header: "Finance Details",
    key: "financeDetails"
  }, _react.default.createElement(_form.default, {
    layout: "inline"
  }, children)));
};

exports.FinanceDetailsForm = FinanceDetailsForm;