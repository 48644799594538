"use strict";

exports.__esModule = true;
exports.getPath = getPath; // pathSuffix will have to home path prepended
// pathPrefix is if you already have a path and are adding to it

function getPath(pathSuffix, pathPrefix) {
  if (pathPrefix === void 0) {
    pathPrefix = "";
  } // eg: getPath("bmi-calculator", "/health/");
  // creates: /health/bmi-calculator
  // eg: getPath("health");
  // creates: /health/


  var prefix = pathPrefix !== "" ? pathPrefix : "/";
  return prefix + pathSuffix + "/";
}