"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.InvestmentsContainer = void 0;

require("antd/lib/checkbox/style");

var _checkbox = _interopRequireDefault(require("antd/lib/checkbox"));

require("antd/lib/button/style");

var _button = _interopRequireDefault(require("antd/lib/button"));

require("antd/lib/card/style");

var _card = _interopRequireDefault(require("antd/lib/card"));

var _taggedTemplateLiteralLoose2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteralLoose"));

var _react = _interopRequireDefault(require("react"));

var _mobxReact = require("mobx-react");

var _styled = require("../../../../styles/styled");

function _templateObject2() {
  var data = (0, _taggedTemplateLiteralLoose2.default)(["\n  && {\n    > * + * {\n      margin-left: 15px;\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteralLoose2.default)(["\n  && {\n    .ant-card-body {\n      background: #eefbff;\n    }\n    .ant-card-head {\n      background: #76c8ff;\n      .ant-card-head-wrapper {\n        color: #fff;\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var InvestmentCard = (0, _styled.styled)(_card.default)(_templateObject());

var MenuWrapper = _styled.styled.div(_templateObject2());

var InvestmentsContainer = (0, _mobxReact.observer)(function (props) {
  var store = props.store,
      children = props.children;

  var onAdd = function onAdd() {
    store.addInvestment();
  };

  var toggleDisplayProducts = function toggleDisplayProducts() {
    store.toggleDisplayProducts();
  };

  var menu = _react.default.createElement(MenuWrapper, null, _react.default.createElement(_button.default, {
    onClick: onAdd
  }, "Add"), _react.default.createElement(_checkbox.default, {
    id: "displayProducts",
    checked: store.displayProducts,
    onChange: toggleDisplayProducts
  }, "Show Products"));

  return _react.default.createElement(InvestmentCard, {
    title: "Investments",
    extra: menu
  }, children);
});
exports.InvestmentsContainer = InvestmentsContainer;