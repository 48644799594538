"use strict";

exports.__esModule = true;
exports.msToTime = msToTime;
exports.msToTimerSeconds = msToTimerSeconds;
exports.msToTimerData = msToTimerData;
exports.msToTimerFormat = msToTimerFormat;
exports.timerDataToTimerFormat = timerDataToTimerFormat;
exports.hmsToSecondsOnly = hmsToSecondsOnly;

function msToTime(ms) {
  return new Date(ms).toISOString().slice(11, -1);
}

function msToTimerSeconds(ms) {
  return new Date(ms).toISOString().slice(11, -5);
}

function msToTimerData(ms) {
  var seconds = Math.ceil(ms / 1000);
  var hh = Math.floor(seconds / 3600);
  var mm = Math.floor((seconds - hh * 3600) / 60);
  var ss = seconds - hh * 3600 - mm * 60;
  return {
    hh: hh,
    mm: mm,
    ss: ss
  };
} // Timer uses the ceiling value as we want to know how much is left
// eg 500ms should equal 1s not 0s.


function msToTimerFormat(ms) {
  var timerData = msToTimerData(ms);
  var hh = "" + timerData.hh;
  var mm = "" + timerData.mm;
  var ss = "" + timerData.ss;

  if (timerData.hh < 10) {
    hh = "0" + timerData.hh;
  }

  if (timerData.mm < 10) {
    mm = "0" + timerData.mm;
  }

  if (timerData.ss < 10) {
    ss = "0" + timerData.ss;
  }

  return hh + ":" + mm + ":" + ss;
}

function timerDataToTimerFormat(timerData) {
  var hh = "" + timerData.hh;
  var mm = "" + timerData.mm;
  var ss = "" + timerData.ss;

  if (timerData.hh < 10) {
    hh = "0" + timerData.hh;
  }

  if (timerData.mm < 10) {
    mm = "0" + timerData.mm;
  }

  if (timerData.ss < 10) {
    ss = "0" + timerData.ss;
  }

  return hh + ":" + mm + ":" + ss;
} //  http://stackoverflow.com/questions/9640266/convert-hhmmss-string-to-seconds-only-in-javascript


function hmsToSecondsOnly(hms) {
  var p = hms.split(":");
  var s = 0;
  var m = 1;

  while (p.length > 0) {
    s += m * parseInt(p.pop(), 10);
    m *= 60;
  }

  return s;
}