"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.FinancialAdviserCalculator = void 0;

require("antd/lib/table/style");

var _table = _interopRequireDefault(require("antd/lib/table"));

var _react = _interopRequireDefault(require("react"));

var _mobxReact = require("mobx-react");

var _Core = require("../../../../../components/Core");

var _FinanceDetails = require("./FinanceDetails");

var FinancialAdviserCalculator = (0, _mobxReact.observer)(function (_ref) {
  var financialAdviserCalculatorStore = _ref.financialAdviserCalculatorStore;
  return _react.default.createElement(_Core.Box, {
    px: 3
  }, _react.default.createElement(_FinanceDetails.FinanceDetails, {
    store: financialAdviserCalculatorStore
  }), _react.default.createElement(_table.default, null));
});
exports.FinancialAdviserCalculator = FinancialAdviserCalculator;