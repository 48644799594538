"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.FinancialAdviserCalculatorStore = void 0;

var _initializerDefineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/initializerDefineProperty"));

var _applyDecoratedDescriptor2 = _interopRequireDefault(require("@babel/runtime/helpers/applyDecoratedDescriptor"));

var _initializerWarningHelper2 = _interopRequireDefault(require("@babel/runtime/helpers/initializerWarningHelper"));

var _mobx = require("mobx");

var _composeValidators = require("../../../../validation/composeValidators");

var _currencyValidator = require("../../../../validation/currencyValidator");

var _requiredValidator = require("../../../../validation/requiredValidator");

var _DisplayValue = require("../../../../common/DisplayValue");

var _class, _descriptor, _descriptor2, _temp;

var FinancialAdviserCalculatorStore = (_class = (_temp = function FinancialAdviserCalculatorStore() {
  this.defaultLocal = "en-US";
  this.defaultCurrency = "USD";
  this.currencyFormatter = new Intl.NumberFormat(this.defaultLocal, {
    style: "currency",
    currency: this.defaultCurrency,
    currencyDisplay: "symbol"
  }).format;
  this.numberParser = parseFloat;
  this.currencyInputValidator = (0, _composeValidators.composeValidators)((0, _requiredValidator.isRequired)(), (0, _currencyValidator.processCurrency)());
  (0, _initializerDefineProperty2.default)(this, "totalInvestment", _descriptor, this);
  (0, _initializerDefineProperty2.default)(this, "yearsToForecast", _descriptor2, this);
} // @action
// addInvestment() {
//   const investment = defaultInvestment(createUniqueId(), createUniqueId())
//   this.investments.unshift(investment)
//   return investment
// }
//
// @action
// findInvestment(id) {
//   return this.investments.find(investments => investments.id === id)
// }
//
// @action
// deleteInvestment(id) {
//   this.investments.remove(this.findInvestment(id))
// }
, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "totalInvestment", [_mobx.observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function initializer() {
    return new _DisplayValue.DisplayValue("500000", this.currencyFormatter, this.numberParser, this.currencyInputValidator);
  }
}), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "yearsToForecast", [_mobx.observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function initializer() {
    return 20;
  }
})), _class);
exports.FinancialAdviserCalculatorStore = FinancialAdviserCalculatorStore;