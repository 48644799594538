"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.CompoundInterestCalculator = void 0;

var _react = _interopRequireDefault(require("react"));

var _mobxReact = require("mobx-react");

var _Investments = require("./Investments");

var _Core = require("../../../../components/Core");

var _FinanceDetails = require("./FinanceDetails");

var CompoundInterestCalculator = (0, _mobxReact.observer)(function (_ref) {
  var compoundInterestStore = _ref.compoundInterestStore;
  return _react.default.createElement(_Core.Box, {
    px: 3
  }, _react.default.createElement(_FinanceDetails.FinanceDetails, {
    store: compoundInterestStore
  }), _react.default.createElement(_Investments.Investments, {
    compoundInterestStore: compoundInterestStore
  }));
});
exports.CompoundInterestCalculator = CompoundInterestCalculator;