"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.Timer = void 0;

require("antd/lib/switch/style");

var _switch = _interopRequireDefault(require("antd/lib/switch"));

require("antd/lib/time-picker/style");

var _timePicker = _interopRequireDefault(require("antd/lib/time-picker"));

require("antd/lib/progress/style");

var _progress = _interopRequireDefault(require("antd/lib/progress"));

require("antd/lib/button/style");

var _button = _interopRequireDefault(require("antd/lib/button"));

var _taggedTemplateLiteralLoose2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteralLoose"));

var _react = _interopRequireDefault(require("react"));

var _mobxReact = require("mobx-react");

var _reactHowler = _interopRequireDefault(require("react-howler"));

var _Blink = _interopRequireDefault(require("../../../../components/Blink"));

var _styled = require("../../../../styles/styled");

var _Core = require("../../../../components/Core");

var _moment = _interopRequireDefault(require("moment"));

function _templateObject4() {
  var data = (0, _taggedTemplateLiteralLoose2.default)(["\n  margin-right: 24px;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteralLoose2.default)(["font-size: 75px"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteralLoose2.default)(["\n  height: 80px;\n  font-size: 80px;\n  font-family: HelveticaNeue-Light;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteralLoose2.default)(["\n  align-items: center;\n  flex-direction: column;\n  padding-top: 12px;\n  padding-bottom: 24px;\n  display: flex;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var timeFormat = "HH:mm:ss";

var TimerWrapper = _styled.styled.div(_templateObject());

var TimerDisplay = _styled.styled.div(_templateObject2(), _Core.media.screenSm(_templateObject3()));

var TimerButton = (0, _styled.styled)(_button.default)(_templateObject4());
var Timer = (0, _mobxReact.observer)(function (_ref) {
  var timerStore = _ref.timerStore;
  var resetButton;
  var startPauseButton;

  var startTimer = function startTimer() {
    timerStore.startTimer();
  };

  var stopTimer = function stopTimer() {
    timerStore.pauseTimer();
  };

  var resetTimer = function resetTimer() {
    timerStore.resetTimer();
  };

  var toggleSound = function toggleSound() {
    timerStore.toggleSound();
  };

  var updateTimeToRun = function updateTimeToRun(data) {
    var secondsToRun = data.diff((0, _moment.default)().startOf("day"), "seconds");
    timerStore.timer.setSecondsToRun(secondsToRun);

    if (!timerStore.isRunning) {
      timerStore.resetTimer();
    }
  };

  resetButton = _react.default.createElement(TimerButton, {
    onClick: resetTimer
  }, "Reset");

  if (!timerStore.isRunning) {
    startPauseButton = _react.default.createElement(TimerButton, {
      onClick: startTimer,
      type: "primary"
    }, "Start");
  } else {
    startPauseButton = _react.default.createElement(TimerButton, {
      onClick: stopTimer,
      type: "primary"
    }, "Pause");
  }

  var mainDisplay = _react.default.createElement(TimerDisplay, null, timerStore.mainDisplay); // TODO: move toggle into Blink for reuse


  var mainDisplayContainer = timerStore.timer.isFinished ? _react.default.createElement(_Blink.default, null, mainDisplay) : _react.default.createElement("span", null, mainDisplay); // const momentTime = moment.duration(timerStore.timer.msToRun, "milliseconds").as;

  var momentTime = (0, _moment.default)(timerStore.mainDisplay, timeFormat);
  return _react.default.createElement(TimerWrapper, null, mainDisplayContainer, timerStore.timer.finishedAt && _react.default.createElement("div", null, "Finished at: ", timerStore.timer.finishedAt.toLocaleTimeString()), _react.default.createElement(_progress.default, {
    percent: timerStore.timer.currentMilliseconds / timerStore.timer.msToRun * 100,
    showInfo: false
  }), _react.default.createElement(_Core.Box, {
    mt: 3
  }, _react.default.createElement(_timePicker.default, {
    format: timeFormat,
    value: momentTime,
    onChange: updateTimeToRun,
    allowClear: false,
    size: "large"
  })), _react.default.createElement("div", null, _react.default.createElement(_Core.Box, {
    mt: 4
  }, resetButton, startPauseButton, _react.default.createElement(_switch.default, {
    checkedChildren: "Sound On",
    unCheckedChildren: "Sound Off",
    onChange: toggleSound,
    checked: timerStore.hasSound
  }))), timerStore.hasSound && _react.default.createElement(_reactHowler.default, {
    src: "/assets/alarm.m4a",
    playing: timerStore.timer.isFinished,
    loop: true
  }));
});
exports.Timer = Timer;