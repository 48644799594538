"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.FinanceContainer = FinanceContainer;

require("antd/lib/alert/style");

var _alert = _interopRequireDefault(require("antd/lib/alert"));

var React = _interopRequireWildcard(require("react"));

function FinanceContainer(props) {
  var fullDisclaimerId = "finance-disclaimer";
  return React.createElement(React.Fragment, null, React.createElement(_alert.default, {
    message: React.createElement("p", null, React.createElement("b", null, "Disclaimer:"), " Information provided does not constitute formal financial advice, see below for further", " ", React.createElement("a", {
      href: "#" + fullDisclaimerId
    }, "details.")),
    type: "warning"
  }), props.children, React.createElement(_alert.default, {
    message: React.createElement(React.Fragment, null, React.createElement("h3", null, "Financial Disclaimer"), React.createElement("p", {
      id: fullDisclaimerId
    }, "This information should not be considered financial advice and is based on our own research and is included only for general educational purposes. Your personal financial situation has not been taken into consideration. While investing can lead to profit, it can also lead to losing all of your original investment. Before investing, you may want to consult a certified personal financial adviser who can tailor advice to your own situation.")),
    type: "warning"
  }));
}