"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.Products = void 0;

require("antd/lib/alert/style");

var _alert = _interopRequireDefault(require("antd/lib/alert"));

var _react = _interopRequireDefault(require("react"));

var _mobxReact = require("mobx-react");

var _ProductContainer = require("./ProductContainer");

var _ProductList = require("./ProductList");

var Products = (0, _mobxReact.observer)(function (_ref) {
  var store = _ref.store,
      investment = _ref.investment;
  return _react.default.createElement(_ProductContainer.ProductContainer, {
    store: store,
    investment: investment
  }, investment.products.length > 0 ? _react.default.createElement(_ProductList.ProductList, {
    store: store,
    investment: investment
  }) : _react.default.createElement(_alert.default, {
    type: "warning",
    message: "No Products, please add."
  }));
});
exports.Products = Products;