"use strict";

exports.__esModule = true;
exports.composeValidators = composeValidators;

function composeValidators() {
  for (var _len = arguments.length, validators = new Array(_len), _key = 0; _key < _len; _key++) {
    validators[_key] = arguments[_key];
  }

  return function (value, fields) {
    var result = {
      isValid: true,
      hasNewValue: false,
      newValue: value
    };

    for (var i = 0; i < validators.length; i++) {
      var validator = validators[i];
      var currentResult = validator(result.newValue, fields);

      if (currentResult.hasNewValue) {
        result.hasNewValue = true;
        result.newValue = currentResult.newValue;
      }

      if (!currentResult.isValid) {
        currentResult.hasNewValue = result.hasNewValue;
        return currentResult;
      }
    }

    return result;
  };
}