"use strict";

exports.__esModule = true;
exports.investorChecklistRoute = investorChecklistRoute;
exports.InvestorChecklistPath = exports.InvestorChecklistSegment = void 0;

var _getPath = require("../../../../../react-static/route/getPath");

var _createRoute = require("../../../../../react-static/route/createRoute");

var _Finance = require("../Finance");

var InvestorChecklistSegment = "checklist-to-consider-when-investing";
exports.InvestorChecklistSegment = InvestorChecklistSegment;
var InvestorChecklistPath = (0, _getPath.getPath)(InvestorChecklistSegment, _Finance.FinancePath);
exports.InvestorChecklistPath = InvestorChecklistPath;

function investorChecklistRoute(rootPath) {
  return (0, _createRoute.createRoute)(rootPath, InvestorChecklistPath);
}