"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.ProductList = void 0;

require("antd/lib/list/style");

var _list = _interopRequireDefault(require("antd/lib/list"));

var _react = _interopRequireDefault(require("react"));

var _mobxReact = require("mobx-react");

var _ProductItem = require("./ProductItem");

var ProductList = (0, _mobxReact.observer)(function (_ref) {
  var store = _ref.store,
      investment = _ref.investment;
  return _react.default.createElement(_list.default, {
    bordered: false,
    split: false
  }, investment.products.map(function (product) {
    return _react.default.createElement(_ProductItem.ProductItem, {
      key: product.id,
      investment: investment,
      product: product,
      store: store
    });
  }));
});
exports.ProductList = ProductList;