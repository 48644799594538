"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.createId = createId;

var _lodash = _interopRequireDefault(require("lodash.snakecase")); // Using snake case (ie _) as it is easier for users to read and modify in URLs
// Can also be used in JavaScript as a property name without modification
// Not sure what to do, but for now returns whatever was passed in if falsy?


function createId(source) {
  return source ? (0, _lodash.default)(source) : source;
}