"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.ProductFee = void 0;

require("antd/lib/form/style");

var _form = _interopRequireDefault(require("antd/lib/form"));

var _react = _interopRequireDefault(require("react"));

var _mobxReact = require("mobx-react");

var _mobx = require("mobx");

var _partialFee = require("../calculations/partialFee");

var _RunIfArgsValid = require("../../../../common/RunIfArgsValid");

var _DisplayValue = require("../../../../common/DisplayValue");

var _LossValue = require("../../../../components/dataDisplay/LossValue");

var FormItem = _form.default.Item;
var productFeeLabel = "Product Fee (pa)";
var ProductFee = (0, _mobxReact.observer)(function (props) {
  var product = props.product,
      store = props.store;
  var feePa = (0, _mobx.computed)(function () {
    return (0, _RunIfArgsValid.runIfArgsValid)(_partialFee.partialFee, store.totalInvestment, product.feePercentage, product.portfolioPercentage);
  });
  var feePaFormatted = new _DisplayValue.DisplayValue(feePa.get().returned, store.currencyFormatter, undefined, function () {
    return feePa.get().isValid;
  });
  return _react.default.createElement(FormItem, {
    label: productFeeLabel
  }, _react.default.createElement(_LossValue.LossValue, null, feePaFormatted.displayValue));
});
exports.ProductFee = ProductFee;