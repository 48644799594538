"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.Neutral = exports.Negative = exports.Positive = void 0;

var _taggedTemplateLiteralLoose2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteralLoose"));

var _styled = require("../../styles/styled");

function _templateObject4() {
  var data = (0, _taggedTemplateLiteralLoose2.default)(["\n  border-color: lightgrey;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteralLoose2.default)(["\n  border-color: #d01c8b;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteralLoose2.default)(["\n  border-color: #4dac26;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteralLoose2.default)(["\n  padding: 8px 15px;\n  margin-bottom: 15px;\n  border-width: medium;\n  border-style: solid;\n  border-radius: 10px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Comparison = _styled.styled.section(_templateObject());

var Positive = (0, _styled.styled)(Comparison)(_templateObject2());
exports.Positive = Positive;
var Negative = (0, _styled.styled)(Comparison)(_templateObject3());
exports.Negative = Negative;
var Neutral = (0, _styled.styled)(Comparison)(_templateObject4());
exports.Neutral = Neutral;