"use strict";

exports.__esModule = true;
exports.defaultInvestment = defaultInvestment;

var _product = require("./product");

var _ReactiveData = require("../../../../common/ReactiveData");

var _CurrencyValue = require("../../../../common/CurrencyValue");

var _PercentageValue = require("../../../../common/PercentageValue");

function defaultInvestment(id, productId, currencyFormatter) {
  return {
    id: id,
    name: new _ReactiveData.ReactiveData(""),
    adminFeePercentage: new _PercentageValue.PercentageValue("0.5"),
    adminFeePa: new _CurrencyValue.CurrencyValue("75", currencyFormatter),
    products: [(0, _product.defaultProduct)(productId, true)]
  };
}