"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.ProductContainer = void 0;

require("antd/lib/button/style");

var _button = _interopRequireDefault(require("antd/lib/button"));

require("antd/lib/card/style");

var _card = _interopRequireDefault(require("antd/lib/card"));

var _taggedTemplateLiteralLoose2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteralLoose"));

var _react = _interopRequireDefault(require("react"));

var _mobxReact = require("mobx-react");

var _styled = require("../../../../styles/styled");

function _templateObject() {
  var data = (0, _taggedTemplateLiteralLoose2.default)(["\n  && {\n    .ant-card-body {\n      background: #eefbff;\n    }\n    .ant-card-head {\n      background: purple;\n      .ant-card-head-wrapper {\n        color: #fff;\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var ProductCard = (0, _styled.styled)(_card.default)(_templateObject());
var ProductContainer = (0, _mobxReact.observer)(function (props) {
  var store = props.store,
      investment = props.investment,
      children = props.children;

  var onAdd = function onAdd() {
    store.addProduct(investment.id);
  };

  var menu = _react.default.createElement(_button.default, {
    onClick: onAdd
  }, "Add");

  return _react.default.createElement(ProductCard, {
    title: "Products",
    extra: menu
  }, children);
});
exports.ProductContainer = ProductContainer;