"use strict";

exports.__esModule = true;
exports.buyingGoldInAustraliaRoute = buyingGoldInAustraliaRoute;
exports.BuyingGoldInAustraliaPath = exports.BuyingGoldInAustraliaSegment = void 0;

var _getPath = require("../../../../../react-static/route/getPath");

var _createRoute = require("../../../../../react-static/route/createRoute");

var _Finance = require("../Finance");

var BuyingGoldInAustraliaSegment = "buying-gold-in-australia";
exports.BuyingGoldInAustraliaSegment = BuyingGoldInAustraliaSegment;
var BuyingGoldInAustraliaPath = (0, _getPath.getPath)(BuyingGoldInAustraliaSegment, _Finance.FinancePath);
exports.BuyingGoldInAustraliaPath = BuyingGoldInAustraliaPath;

function buyingGoldInAustraliaRoute(rootPath) {
  return (0, _createRoute.createRoute)(rootPath, BuyingGoldInAustraliaPath);
}