"use strict";

exports.__esModule = true;
exports.HomeSeoMetadata = HomeSeoMetadata;
exports.homeRoute = homeRoute;
exports.FinanceTileRef = exports.TimingTileRef = exports.HealthTileRef = exports.HomePath = void 0;

var _createRoute = require("../../../react-static/route/createRoute");

var _getPath = require("../../../react-static/route/getPath");

var _Health = require("./health/Health");

var _Timing = require("./timing/Timing");

var _Finance = require("./finance/Finance");

var _SiteConfig = require("../data/SiteConfig");

var HomePath = "/";
exports.HomePath = HomePath;
var HealthTileRef = (0, _getPath.getPath)("#" + _Health.HealthId, HomePath);
exports.HealthTileRef = HealthTileRef;
var TimingTileRef = (0, _getPath.getPath)("#" + _Timing.TimingId, HomePath);
exports.TimingTileRef = TimingTileRef;
var FinanceTileRef = (0, _getPath.getPath)("#" + _Finance.FinanceId, HomePath);
exports.FinanceTileRef = FinanceTileRef;

function HomeSeoMetadata() {
  return {
    description: "Eldar Labs provides useful online tools and utilities.",
    title: _SiteConfig.config.siteTitle + " - " + _SiteConfig.config.siteDescription
  };
}

function homeRoute(rootPath) {
  var title = _SiteConfig.config.siteTitle;
  var route = (0, _createRoute.createRoute)(rootPath, HomePath);

  route.getData = function () {
    return {
      path: HomePath,
      title: title
    };
  };

  return route;
}