"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _Core = require("../../../../components/Core");

function Disclaimer() {
  return _react.default.createElement(_Core.Box, {
    px: 3,
    mt: 3
  }, _react.default.createElement("h3", null, "Disclaimer"), _react.default.createElement("ul", null, _react.default.createElement("li", null, "This website is provided for information only, it should not be considered financial advice."), _react.default.createElement("li", null, "You agree to determining the usefulness of the information found here yourself or via advisers and take all liability for acting upon any information provided."), _react.default.createElement("li", null, "We try ensure the accuracy of the information and calculations provided, but do not guarantee or provide warranty of information and calculations."), _react.default.createElement("li", null, "You agree to use this site at your own risk."), _react.default.createElement("li", null, "The website has no continuation of service agreements and the site may change at any time."), _react.default.createElement("li", null, "You agree not to hold us responsible for data loss or interruption of service of any kind.")), _react.default.createElement("h3", null, "Calculations"), _react.default.createElement("ul", null, _react.default.createElement("li", null, "Using large decimals for accuracy. A future version may change to decrease calculation time."), _react.default.createElement("li", null, "Currently supports $ currency to 2 decimal places with Banker's Rounding. We hope to allow different currencies in the future.")));
}

var _default = Disclaimer;
exports.default = _default;