"use strict";

exports.__esModule = true;
exports.alarmTimerRoute = alarmTimerRoute;
exports.AlarmTimerPath = exports.AlarmTimerSegment = void 0;

var _Timing = require("../Timing");

var _getPath = require("../../../../../react-static/route/getPath");

var _createRoute = require("../../../../../react-static/route/createRoute");

var AlarmTimerSegment = "alarm-timer";
exports.AlarmTimerSegment = AlarmTimerSegment;
var AlarmTimerPath = (0, _getPath.getPath)(AlarmTimerSegment, _Timing.TimingPath);
exports.AlarmTimerPath = AlarmTimerPath;

function alarmTimerRoute(rootPath) {
  return (0, _createRoute.createRoute)(rootPath, AlarmTimerPath);
}