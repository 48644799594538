"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.FinanceDetails = void 0;

var _react = _interopRequireDefault(require("react"));

var _mobxReact = require("mobx-react");

var _FinanceDetailsForm = require("../FinanceDetailsForm");

var _StartingBalanceFormInput = require("../StartingBalanceFormInput");

var FinanceDetails = (0, _mobxReact.observer)(function (_ref) {
  var store = _ref.store;
  return _react.default.createElement(_FinanceDetailsForm.FinanceDetailsForm, null, _react.default.createElement(_StartingBalanceFormInput.StartingBalanceFormInput, {
    totalInvestment: store.totalInvestment
  }));
});
exports.FinanceDetails = FinanceDetails;