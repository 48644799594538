"use strict";

exports.__esModule = true;
exports.isValidArgs = isValidArgs;

function isValidArgs() {
  var isValid = true;

  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }

  for (var _i = 0, _args = args; _i < _args.length; _i++) {
    var item = _args[_i];

    if (item.isValid === false) {
      isValid = false;
      break;
    }
  }

  return isValid;
}