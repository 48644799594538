"use strict";

exports.__esModule = true;
exports.CalculatorsPath = exports.CalculatorsSegment = void 0;

var _getPath = require("../../../../react-static/route/getPath");

var CalculatorsSegment = "calculators";
exports.CalculatorsSegment = CalculatorsSegment;
var CalculatorsPath = (0, _getPath.getPath)(CalculatorsSegment);
exports.CalculatorsPath = CalculatorsPath;