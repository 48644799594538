"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.Stopwatch = exports.StopwatchTitle = void 0;

require("antd/lib/button/style");

var _button = _interopRequireDefault(require("antd/lib/button"));

var _taggedTemplateLiteralLoose2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteralLoose"));

var _react = _interopRequireDefault(require("react"));

var _mobxReact = require("mobx-react");

var _LapTable = _interopRequireDefault(require("./LapTable"));

var _Core = require("../../../../components/Core");

var _styled = require("../../../../styles/styled");

function _templateObject4() {
  var data = (0, _taggedTemplateLiteralLoose2.default)(["\n  margin-right: 24px;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteralLoose2.default)(["font-size: 45px"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteralLoose2.default)(["\n  height: 80px;\n  font-size: 80px;\n  font-family: HelveticaNeue-Light;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteralLoose2.default)(["\n  align-items: center;\n  flex-direction: column;\n  padding-top: 12px;\n  padding-bottom: 24px;\n  display: flex;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StopwatchTitle = "Stopwatch";
exports.StopwatchTitle = StopwatchTitle;

var StopWatchWrapper = _styled.styled.div(_templateObject());

var StopwatchDisplay = _styled.styled.div(_templateObject2(), _Core.media.screenSm(_templateObject3()));

var StopwatchButton = (0, _styled.styled)(_button.default)(_templateObject4());
var Stopwatch = (0, _mobxReact.observer)(function (_ref) {
  var stopwatchStore = _ref.stopwatchStore;
  var resetButton;
  var lapButton;
  var startPauseButton;

  var startTimer = function startTimer() {
    stopwatchStore.startTimer();
  };

  var stopTimer = function stopTimer() {
    stopwatchStore.stopTimer();
  };

  var resetTimer = function resetTimer() {
    stopwatchStore.resetTimer();
  };

  var lapTimer = function lapTimer() {
    stopwatchStore.lapTimer();
  };

  resetButton = _react.default.createElement(StopwatchButton, {
    onClick: resetTimer
  }, "Reset");
  lapButton = _react.default.createElement(StopwatchButton, {
    onClick: lapTimer
  }, "Lap");

  if (!stopwatchStore.isRunning) {
    startPauseButton = _react.default.createElement(_button.default, {
      onClick: startTimer,
      type: "primary"
    }, "Start");
  } else {
    startPauseButton = _react.default.createElement(_button.default, {
      onClick: stopTimer,
      type: "primary"
    }, "Pause");
  }

  return _react.default.createElement(StopWatchWrapper, null, _react.default.createElement(StopwatchDisplay, null, stopwatchStore.mainDisplay), _react.default.createElement("div", null, _react.default.createElement(_Core.Box, {
    mt: 4
  }, resetButton, lapButton, startPauseButton)), stopwatchStore.length > 0 && _react.default.createElement(_Core.Box, {
    mt: 3
  }, _react.default.createElement(_LapTable.default, {
    stopwatchStore: stopwatchStore
  })));
});
exports.Stopwatch = Stopwatch;